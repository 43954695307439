import axios from "axios";

import { FetchModelo, FetchCatalog, FetchFondoColor, FetchManipulados, FetchSublimacionSizes, FetchDtfNumerosSizes, FetchTransferNumerosSizes } from "@/api/reprocesos";

function parseModels(catalog) {
  let proxyModels = {};
  let productMap = {};
  let categoryCount = {};

  Object.entries(catalog.MODELS).forEach(function (entry) {

    const [modelKey, model] = entry;


    let proxyModel = {};
    proxyModel.code = modelKey;
    proxyModel.slug = model.IDSAP;

    //Para las fotos de un modelo hay 7 posibles propiedades de las que se obtiene
    // ADULT -> Foto modelo para adulto
    // CHILD -> Foto modelo para niño.
    // VIEWS -> Vistas del modelo (lateral, frontal, trasera ...)
    // DETAIL -> Vista en detalle del modelo (close-ups)
    // OTHER -> no usado en front (no se sabe muy bien para que se usan estas)
    // SCHEME -> 
    // CAMPAIGN -> por ahora no utlizado
    // PRINT -> Imagenes de impresión


    //Guardar en el array de large pictures ADULT, CHILD, DETAIL, VIEWS, OTHER en ese orden.
    proxyModel.large_pictures = [];
    proxyModel.image_views = [];
    proxyModel.image_others = [];
    proxyModel.image_print = [];
    if (model.ADULT) {
      for (const [colorId, colorImgs] of Object.entries(model.ADULT)) {
        for (const [typeId, typeImgs] of Object.entries(colorImgs)) {
          for (const [indexId, picture] of Object.entries(typeImgs)) {
            proxyModel.large_pictures.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "adult"
            })
          }
        }
      }
      //guardar la imagen de adult  como propiedad separada para fácil acceso.
      if (Object.entries(model.ADULT)[0]) {
        const [colorId, colorImg] = Object.entries(model.ADULT)[0];
        const [typeId, typeImg] = Object.entries(colorImg)[0];
        const [indexId, adultPicture] = Object.entries(typeImg)[0];
        proxyModel.adult_picture = {
          url: `${process.env.VUE_APP_STATIC_IMG_URL}${adultPicture.IMG}`,
          width: 250, //del prototipo - podría ser eliminable
          height: 250, //del prototipo - podría ser eliminable
          isHD: adultPicture.HD,
          index: indexId,
          typeInt: typeId,
          color: colorId,
          type: "adult"
        }
      }

    }


    if (model.OTHER) {
      for (const [colorId, colorImgs] of Object.entries(model.OTHER)) {
        for (const [typeId, typeImgs] of Object.entries(colorImgs)) {
          for (const [indexId, picture] of Object.entries(typeImgs)) {
            proxyModel.large_pictures.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "other"
            })
            proxyModel.image_others.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "other"
            })
          }
        }
      }
    }


    if (model.CHILD) {
      for (const [colorId, colorImgs] of Object.entries(model.CHILD)) {
        for (const [typeId, typeImgs] of Object.entries(colorImgs)) {
          for (const [indexId, picture] of Object.entries(typeImgs)) {
            proxyModel.large_pictures.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "child"
            })
          }
        }
      }
      //guardar la imagen  de child como propiedad separada para fácil acceso.
      if (Object.entries(model.CHILD)[0]) {
        const [colorId, colorImg] = Object.entries(model.CHILD)[0];
        const [typeId, typeImg] = Object.entries(colorImg)[0];
        const [indexId, childPicture] = Object.entries(typeImg)[0];
        proxyModel.child_picture = {
          url: `${process.env.VUE_APP_STATIC_IMG_URL}${childPicture.IMG}`,
          width: 250, //del prototipo - podría ser eliminable
          height: 250, //del prototipo - podría ser eliminable
          isHD: childPicture.HD,
          index: indexId,
          typeInt: typeId,
          color: colorId,
          type: "child"
        }
      }
    }



    if (model.DETAIL) {
      for (const [colorId, colorImgs] of Object.entries(model.DETAIL)) {
        for (const [typeId, typeImgs] of Object.entries(colorImgs)) {
          for (const [indexId, picture] of Object.entries(typeImgs)) {
            proxyModel.large_pictures.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "detail"
            })
          }
        }
      }
    }

    if (model.VIDEO) {
      for (const [colorId, colorImgs] of Object.entries(model.VIDEO)) {
        for (const [typeId, typeImgs] of Object.entries(colorImgs)) {
          for (const [indexId, picture] of Object.entries(typeImgs)) {
            proxyModel.large_pictures.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "video"
            })
          }
        }
      }
    }


    if (model.VIEWS) {
      for (const [colorId, colorImgs] of Object.entries(model.VIEWS)) {
        for (const [typeId, typeImgs] of Object.entries(colorImgs)) {
          for (const [indexId, picture] of Object.entries(typeImgs)) {
            proxyModel.large_pictures.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "views"
            });
            proxyModel.image_views.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "views"
            })
          }
        }
      }
    }

    if (model.PRINT) {
      for (const [colorId, colorImgs] of Object.entries(model.PRINT)) {
        for (const [typeId, typeImgs] of Object.entries(colorImgs)) {
          for (const [indexId, picture] of Object.entries(typeImgs)) {
            proxyModel.large_pictures.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "print"
            });
            proxyModel.image_print.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              index: indexId,
              typeInt: typeId,
              color: colorId,
              type: "print"
            })
          }
        }
      }
    }

    //Actualemente no existe en los modelos, pero podría ser interesante.
    proxyModel.small_pictures = []

    //guardamos aparte las imagenes del schema.
    proxyModel.scheme = []
    if (model.SCHEME) {
      for (const [colorId, colorImgs] of Object.entries(model.SCHEME)) {
        for (const [typeId, typeImgs] of Object.entries(colorImgs)) {
          for (const [, picture] of Object.entries(typeImgs)) {
            proxyModel.scheme.push({
              url: `${process.env.VUE_APP_STATIC_IMG_URL}${picture.IMG}`,
              width: 250, //del prototipo - podría ser eliminable
              height: 250, //del prototipo - podría ser eliminable
              isHD: picture.HD,
              typeInt: typeId,
              color: colorId,
              type: "scheme"
            })
          }
        }
      }
    }

    if (model.MEASUREPICS) {
      proxyModel.measure_pics = `${process.env.VUE_APP_STATIC_IMG_URL}${Object.values(model.MEASUREPICS)[0]}`;
    } else {
      proxyModel.measure_pics = "";
    }


    proxyModel.outlet_tara = model.OUTLET_TARA;
    proxyModel.family = model.FAMILY;
    proxyModel.isforchildren = model.CHILDREN;
    proxyModel.is_new = model.NOVELTY;
    proxyModel.new_color = model.NEW_COLOR;

    let products = Object.values(model.PRODUCTS);
    let hasNoOultetProducts = products.some(prod => !prod.OUTLET);
    proxyModel.isoutlet = !hasNoOultetProducts;
    proxyModel.ispartialoutlet = hasNoOultetProducts && products.some(prod => prod.OUTLET);

    proxyModel.isoffer = false;

    proxyModel.collections = model.COLLECTIONS || [];
    proxyModel.categories = model.CATEGORIES || [];

    //count categories:
    proxyModel.categories.forEach((catId) => {
      if (!categoryCount[catId]) {
        categoryCount[catId] = 1;
      } else {
        categoryCount[catId]++;
      }
    })

    proxyModel.shop = model.SHOP;
    proxyModel.product_categories = [];

    //bug de migración s4hana. TODO: conseguir solucionar sin este if.
    if (model.COLORS['']) {
      delete model.COLORS[''];
    }
    proxyModel.colors = model.COLORS;
    proxyModel.icons = model.ICONS;

    //bug de migración s4hana. TODO: conseguir solucionar sin este if.
    if (model.SIZES['']) {
      delete model.SIZES[''];
    }
    proxyModel.sizes = model.SIZES;

    proxyModel.related = model.RELATIONAL || [];

    proxyModel.page = model.PAGE;

    proxyModel.products = model.PRODUCTS;
    //para ir cambiando en el codebase variants por products. //TODO ELIMINAR ESTA PROPIEDAD en un futuro.
    proxyModel.variants = proxyModel.products;
    proxyModel.gender = model.GENDER;

    proxyModel.printingTech = model.REPROCESOS || [];

    for (const product in proxyModel.products) {
      productMap[product] = proxyModel.code;
    }

    proxyModel.orden = model.ORDEN;

    proxyModel.materials = model.MATERIALS;
    proxyModel.taric = model.TARIC;
    proxyModel.canonDigital = model.CANON_DIGITAL;
    proxyModel.originalShop = model.SHOP_ORIGINAL;
    proxyModel.pictureModelHeight = model.ALTURAMODELO;
    proxyModel.pictureModelSize = model.TALLAMODELO;
    proxyModel.equivalent = model.EQUIVALENT;
    proxyModel.duoconcept = model.DUOCONCEPT;
    proxyModel.id3dModel = model.ID3DMODEL;

    proxyModels[modelKey] = proxyModel;

  });
  return { proxyModels, productMap, categoryCount }
}

export default {

  async InitCotizador({ commit }) {
    commit("ADD_LOADING");
    let promise = new Promise((resolve, reject) => {
      FetchCatalog()
        .then((response) => {
          let { proxyModels } = parseModels(response.data);
          commit("SET_CATALOG_INFO", proxyModels);

          resolve(response.data);

        })
        .catch((error) => {
          reject(error);
        });
    }).finally(() => {
      commit("REMOVE_LOADING");
      commit("SET_WAIT_EXTRANET", null);
    });

    commit("SET_WAIT_EXTRANET", promise);

    return promise;
  },

  async fetchColorFondo({ commit, state }) {
    commit("ADD_LOADING");

    return new Promise((resolve, reject) => {
      if (state.colorFondo) resolve(state.colorFondo);

      FetchFondoColor()
        .then(response => {
          commit("SET_COLOR_FONDO", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("REMOVE_LOADING");
        });
    });

  },


  async gettamanosTransferEspecialesByCalidad({ commit }, calidad) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_WEB_MARCADO}/Reprocesos/GetTamanosTransferEspecialesByCalidad?calidad=` +
          calidad.c +
          "&key=" +
          calidad.k +
          "&modelCode=" +
          calidad.m
        )
        .then((response) => {
          commit("GET_TAMANOS_TAMANOS_TRANSFERESPECIALES_BYCALIDAD", calidad);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }).finally(() => {
      commit("REMOVE_LOADING");
    });
  },
  async getgettamanosTransferEtiquetas({ commit }, calidad) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_WEB_MARCADO}/Reprocesos/gettamanosTransferEtiquetas?modelCode=` +
          calidad.c +
          "&key=" +
          calidad.k
        )
        .then((response) => {
          commit("GET_TAMANOS_TAMANOS_TRANSFERETIQUETAS", calidad);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }).finally(() => {
      commit("REMOVE_LOADING");
    });
  },
  async getmanipulaciones({ commit, rootGetters }, modelo) {
    if (!modelo?.c) return;
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      FetchManipulados({
        modelCode: modelo.c,
        language: rootGetters["config/actualLanguageRegion"]
      })
        .then((response) => {
          commit("SET_MANIPULADOS", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }).finally(() => {
      commit("REMOVE_LOADING");
    });
  },
  async updateImagenModelo({ commit }, payload) {
    commit("UPDATE_IMAGEN_MODELO", payload);
  },
  async gettamanosSublimacion({ commit }, key) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      FetchSublimacionSizes({
        modelCode: key.m
      })
        .then((response) => {
          commit;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }).finally(() => {
      commit("REMOVE_LOADING");
    });
  },

  async fetchSizeNumerosDtf({ commit }) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      FetchDtfNumerosSizes()
        .then((response) => {
          commit;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }).finally(() => {
      commit("REMOVE_LOADING");
    });

  },

  async fetchSizeNumerosTransfer({ commit }) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      FetchTransferNumerosSizes()
        .then((response) => {
          commit;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }).finally(() => {
      commit("REMOVE_LOADING");
    });

  },

  async getTamanoByMedidas({ commit }, key) {
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_WEB_MARCADO}/Reprocesos/GetTamanoByMedidas?` +
          "tecnica=" +
          key.tecnica +
          "&ancho=" +
          key.ancho +
          "&alto=" +
          key.alto +
          "&key=" +
          key.k
        )
        .then((response) => {
          commit;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }).finally(() => {
      commit("REMOVE_LOADING");
    });
  },

  restoreModel({ commit }) {
    commit("UPDATE_MODELS_PRICES", null);
  },

  async getModelAndPricesFromExtranet({ commit, rootGetters, dispatch, getters }, payload) {
    if (!payload.model) return null;
    commit("ADD_LOADING");
    const modelCode = payload.model.replace(/\D/g, "");

    let key = payload.key;

    if (!key) {
      key = rootGetters["config/isYouReprocesos"] ? rootGetters["config/youReprocesosSession"] : rootGetters["config/key"]
    }

    if (getters.modelCode && modelCode.includes(getters.modelCode)) {
      console.warn("Modelo ya cargado");
      return getters.modelPrice;
    }

    // commit("SET_WAIT_EXTRANET", true);

    let promise = new Promise((resolve, reject) => {
      FetchModelo({
        modelCode,
        isYouReprocesos: payload.isYouReprocesos ?? rootGetters["config/isYouReprocesos"],
        reprocesosKey: key,
        language: rootGetters['config/actualLanguageRegion']
      }).then(response => {
        let proxyModel = {};
        const modelCatalog = response.data.catalog;
        proxyModel.code = modelCode;
        proxyModel.slug = modelCatalog.model.idSap;
        proxyModel.idSap = modelCatalog.model.idSap;
        proxyModel.family = modelCatalog.model.family;
        proxyModel.isforchildren = modelCatalog.model.isforchildren;
        proxyModel.is_new = modelCatalog.model.novelty;
        proxyModel.new_color = modelCatalog.model.hasNewColor;

        let hasNoOultetProducts = modelCatalog.packBoxResume.some(prod => !prod.isoutlet);
        proxyModel.isoutlet = !hasNoOultetProducts;
        proxyModel.ispartialoutlet = hasNoOultetProducts && modelCatalog.packBoxResume.some(prod => prod.isoutlet);

        proxyModel.isoffer = false;

        proxyModel.collections = modelCatalog.collections || [];
        proxyModel.categories = modelCatalog.categories || [];
        proxyModel.shop = modelCatalog.model.shop;

        proxyModel.product_categories = [],

          proxyModel.colors = modelCatalog.colors;
        proxyModel.icons = modelCatalog.icons;

        proxyModel.sizes = modelCatalog.sizes;
        proxyModel.related = modelCatalog.relation || [];

        proxyModel.page = modelCatalog.model.pagecatalog;

        proxyModel.products = {};
        modelCatalog.packBoxResume.forEach(prod => {
          proxyModel.products[prod.code] = {
            PACK: prod.pack,
            BOX: prod.box,
            CODE: prod.code,
            COLOR: prod.color,
            SIZE: prod.size,
            MOQ: prod.moq
          };
        })
        proxyModel.gender = modelCatalog.model.gender;

        proxyModel.printingTech = modelCatalog.reprocesos || [];

        proxyModel.orden = modelCatalog.model.orden;

        proxyModel.materials = modelCatalog.materials;
        proxyModel.taric = modelCatalog.model.taric;
        proxyModel.originalShop = modelCatalog.model.originalShop;
        proxyModel.pictureModelHeight = modelCatalog.model.alturapersonamodelo;
        proxyModel.pictureModelSize = modelCatalog.TALLAMODELO;
        proxyModel.minUnit = modelCatalog.model.minunitsale;

        proxyModel.hasCanonDigital = modelCatalog.model.hasCanonDigital;

        response.data.catalog = proxyModel;

        commit("UPDATE_MODELS_PRICES", response.data);

        dispatch("carrito/restoreCarrito", payload.model, { root: true })

        dispatch("config/cleanSizeDigital", null, { root: true });

        resolve(response);
      })
        .catch(error => reject(error));
    }).finally(() => {
      console.log("FINALLY MODEL EXTRANET");
      commit("REMOVE_LOADING");
      commit("SET_WAIT_EXTRANET", null);
    });
    console.log("PROMISE CREATED", promise);
    commit("SET_WAIT_EXTRANET", promise);

    return promise;
  },
  async fetchSizeDigital({ rootGetters, commit }, payload) {
    if (!payload.model) return null;
    commit("ADD_LOADING");
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_API_REPROCESOS}/Reprocesos/Tamanos/Digital?modelCode=${payload.model}&posicion=${payload.posicion}`,
          {
            headers: {
              ReprocesosKey: rootGetters["config/isYouReprocesos"] ? rootGetters["config/youReprocesosSession"] : rootGetters["config/key"]
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          return false;
        });
    }).finally(() => {
      commit("REMOVE_LOADING");
    });
  },
  addAlert({ commit }, alert) {
    commit("ADD_ALERT", alert)
  },
  clearAlert({ commit }) {
    commit("ADD_ALERT", {}
    )
  }
};
